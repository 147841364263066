const $ = require('jquery');

window.jQuery = $;
window.$ = $;


window.checkIf2FaRequired = function() {
	const $verificationField = $('#verification-field');
	const $passwordField = $('#password-field');
	const $usernameField = $('#user-field');

	if ($verificationField.parent('.form-group').is(':not(.hidden)') ||
		!$usernameField.val() ||
		!$passwordField.val()) {

		$passwordField.prop('disabled', false);
		$usernameField.prop('disabled', false);

		return $('form').submit();
	}

	const $button = $('#login-button');
	const $forgotPassLink = $('#forgot-password-link');
	const $lost2faLink = $('#lost-2fa-link');

	$button.prop('disabled', true);
	$passwordField.prop('disabled', true);
	$usernameField.prop('disabled', true);

	$.ajax({
		method: 'post',
		url: '/check2faenabled',
		data: {
			username: $usernameField.val(),
			password: $passwordField.val()
		}
	}).then(
		(data) => {
			if (data.enabled) {
				$button.prop('disabled', false);

				$forgotPassLink.toggleClass('hidden', true);
				$lost2faLink.toggleClass('hidden', false);

				$verificationField.parent('.form-group').toggleClass('hidden', false);
				$verificationField.focus();
			} else {
				$passwordField.prop('disabled', false);
				$usernameField.prop('disabled', false);

				return $('form').submit();
			}
		},
		(r) => {
			$passwordField.prop('disabled', false);
			$usernameField.prop('disabled', false);
			$button.prop('disabled', false);
		}
	);
};
